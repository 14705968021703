import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useViewbox } from '../../utils/use-viewbox';

// Background Image is fallback for canvas, while the initial sequence is loading.
const StyledContainer = styled.div<{ height: number; width: number }>`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  height: ${({ height }) => (height ? `${height}px` : '100vh')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  background-size: cover;
  position: relative;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

// eslint-disable-next-line react/function-component-definition

export const ScrollImagesContainer: React.FC<PropsWithChildren> = ({ children, ...ueProps }) => {
  const { height, width } = useViewbox();

  return (
    <StyledContainer height={height} width={width} {...ueProps}>
      {children}
    </StyledContainer>
  );
};
