import styled from 'styled-components';

export const StyledPicture = styled.picture<{ imgWidth: number }>`
  width: ${({ imgWidth }) => (imgWidth ? `${imgWidth}px` : '100vw')};
`;

export const StyledImage = styled.img<{ imgWidth: number; imgHeight: number }>`
  width: ${({ imgWidth }) => (imgWidth ? `${imgWidth}px` : '100%')};
  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight}px` : '100vh')};
  object-fit: cover;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;
