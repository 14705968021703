import { audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react';
import {
  FootnoteReferenceV2,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { createElement, ReactNode } from 'react';
import { I18nMessages, InitialState } from '../FeatureAppTypes';
import { fetchI18nMessages } from '../i18n';
import { Content, ThemeType } from '../types';

let i18nMessages: I18nMessages;

export async function createInitialState(
  content: Content,
  localeService: LocaleServiceV1,
  i18nService: I18NServiceV1,
): Promise<InitialState> {
  if (!i18nMessages) {
    i18nMessages = await fetchI18nMessages(localeService, i18nService);
  }

  let additionalText: Iterable<ReactNode> | undefined;

  if (content.additionalText) {
    additionalText = renderTextWithFootnotesReferencesV2(content.additionalText);
  }

  const theme = content.theme === ThemeType.dark ? audiDarkTheme : audiLightTheme;
  const themeWltp = content.themeWltp === ThemeType.dark ? audiDarkTheme : audiLightTheme;

  return { ...content, theme, i18nMessages, additionalText, themeWltp };
}

const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[],
): undefined | string | ReactNode[] => {
  // if it's undefined or a string it doesn't contain any footnotes -> nothing to do
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReferenceV2 /> react component
    return createElement(FootnoteReferenceV2, serializedReactNode.props as undefined);
  });
};

export function deserializeState(serialized: string): InitialState {
  const props = JSON.parse(serialized);

  return {
    ...props,
    additionalText: deserializeReactNodeArray(props.additionalText),
  };
}
