import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { I18nMessages } from '../FeatureAppTypes';

const i18nScope = 'fa.audi-feature-app-svg-scroll-zoom-module';

export async function fetchI18nMessages(
  localeService: LocaleServiceV1,
  i18nService: I18NServiceV1,
): Promise<I18nMessages> {
  return new Promise((resolve, reject) => {
    try {
      i18nService.setScopes([i18nScope]);
      i18nService.registerCallback((messages) => {
        const res = Object.entries(messages[localeService.language]).reduce(
          (acc: Record<string, string>, [key, value]) => {
            // TODO: remove this as soon as i18nService is CMS agnostic (keys don't required FA prefix/scope)
            // messages are prefixed with the i18nScope (feature app name: fa.cct-teaser). This is required due to
            // how AEM handles translations. In order to avoid verbosity in the source code the scope is removed from
            // the keys to make them match the required I18nMessageKey.
            // This way translations can be used like `useI18n('headline')`
            // instead of `useI18n('fa.cct-teaser.headline')`
            if (key.includes(i18nScope)) {
              // eslint-disable-next-line no-param-reassign
              key = key.replace(`${i18nScope}.`, '');

              acc[key] = value;
            }

            return acc;
          },
          {},
        ) as I18nMessages;

        resolve(res);
      });
    } catch (err) {
      reject(err);
    }
  });
}

export { I18nContextProvider } from './I18nContext';
