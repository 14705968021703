import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { CreateBoxContext } from '../../utils/create-context-box';
import { useBoxScroll } from '../../utils/use-box-scroll';
import { useClientRect } from '../../utils/use-client-rect';
import { useViewbox } from '../../utils/use-viewbox';

type Ref = React.RefObject<HTMLDivElement>;

const Outer = styled.div<{ ref: Ref; height?: string; isImmersive?: boolean }>`
  ${({ isImmersive }) => (isImmersive ? 'margin: 0 -8px;' : null)}
  box-sizing: border-box;
  height: ${({ height = '300vh' }) => height};
  position: relative;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

const Inner = styled.div<{ ref: Ref; height?: string }>`
  position: sticky;
  top: 0;
  height: ${(props) => props.height || '100vh'};
  box-sizing: inherit;
  overflow: hidden;
  .adobe-ue-edit &,
  .adobe-ue-preview & {
    // this variable is provided by the UE integration
    // it defines the initial viewport height in px
    // before the UE started resizing the iframe
    max-height: var(--ue-viewport-height);
  }
`;

export interface ScrollBoxProps {
  children: ReactNode;
  outerHeight?: string;
  innerHeight?: string;
  isImmersive?: boolean;
  onScroll?: (position: number) => void;
}

export const ScrollBox: React.FC<ScrollBoxProps> = ({
  children,
  outerHeight,
  innerHeight,
  isImmersive,
  onScroll,
}) => {
  const { width, height, orientation } = useViewbox();
  const outerRef: Ref = React.useRef<HTMLDivElement>(null);
  const innerRef: Ref = React.useRef<HTMLDivElement>(null);
  const viewBox = useClientRect(innerRef);
  const { position } = useBoxScroll({ outerRef, innerRef });

  React.useEffect(() => {
    if (onScroll) {
      onScroll(position);
    }
  }, [position, width, height, orientation]);

  return (
    <CreateBoxContext.Provider value={viewBox}>
      <Outer ref={outerRef} height={outerHeight} isImmersive={isImmersive}>
        <Inner ref={innerRef} height={innerHeight}>
          {children}
        </Inner>
      </Outer>
    </CreateBoxContext.Provider>
  );
};
