import React, { useContext } from 'react';
import { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { Dependencies } from '../FeatureAppTypes';

interface State {
  readonly i18nService: I18NServiceV1;
  readonly localeService: LocaleServiceV1;
  readonly logger?: Logger;
  readonly contentService: ContentServiceV1;
  readonly trackingService?: TrackingServiceV2;
  readonly oneGraphService: OneGraphServiceV1;
  readonly renderModeService: RenderModeServiceV1;
}

export const Context = React.createContext<State>({} as State);

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
}

// eslint-disable-next-line react/function-component-definition
export const ContextProvider = ({ featureServices, children }: ContextValue): JSX.Element => {
  const {
    'audi-content-service': contentService,
    'locale-service': localeService,
    'audi-tracking-service': trackingService,
    's2:logger': logger,
    'onegraph-service': oneGraphService,
    'dbad:audi-i18n-service': i18nService,
    'audi-render-mode-service': renderModeService,
  } = featureServices;

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state: State = {
    localeService,
    i18nService,
    logger,
    contentService,
    trackingService,
    oneGraphService,
    renderModeService,
  };

  return (
    <Context.Provider value={state}>
      <div>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => useContext(Context).logger;
