/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@audi/audi-ui-react';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { I18nContextProvider } from './i18n';
import { AsyncFeatureAppProps, InitialState } from './FeatureAppTypes';
import { Module } from './components/svg-scroll-zoom-module/Module';
import { Context } from './context';
import { createInitialState } from './utils/stateUtils';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

const FeatureApp: React.FunctionComponent<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  const [state, setState] = useState<InitialState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  useEffect(() => {
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SVG Scroll Zoom: SSR did not serialize any state');
      asyncStateHolder().then(setState);
    } else {
      logger?.info('SVG Scroll Zoom: SSR serialized state', asyncStateHolder);
    }
  }, []);

  const { localeService, i18nService, logger, contentService, renderModeService } =
    useContext(Context);

  const initialContent = contentService.getContent();
  const [content, setContent] = useState(mapHeadlessContent(initialContent));

  useEffect(() => {
    let mounted = true;

    const contentChangeListener = () => {
      if (mounted) {
        const newInitialContent = contentService.getContent();
        setContent(mapHeadlessContent(newInitialContent));
      }
    };
    contentService.onContentChange(contentChangeListener);

    return () => {
      mounted = false;
      contentService.removeOnContentChange(contentChangeListener);
    };
  }, [contentService]);

  useEffect(() => {
    if (content) {
      createInitialState(content, localeService, i18nService).then((initialState) => {
        setState(initialState);
      });
    }
  }, [initialContent]);

  if (!state) {
    return null;
  }
  logger?.info('SVG Scroll Zoom: state', state);

  return (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <I18nContextProvider i18nMessages={state.i18nMessages}>
        <ThemeProvider>
          <Module {...state} />
        </ThemeProvider>
      </I18nContextProvider>
    </UniversalEditorProvider>
  );
};

export default FeatureApp;
