/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
} from '@oneaudi/feature-app-utils';
import { AsyncStateHolder, Dependencies } from './FeatureAppTypes';
import { createInitialState, deserializeState } from './utils/stateUtils';
import { ContextProvider } from './context';
import AsyncFeatureApp from './FeatureApp';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      'audi:envConfigService': '^1.0.0',
      'locale-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-content-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
      'onegraph-query-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '^3.x',
      '@feature-hub/react': '^3.5.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '^5.3.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.1.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [],
  create: ({ featureServices, featureAppId }: FeatureAppEnvironment<Dependencies, void>) => {
    const {
      'audi-content-service': contentService,
      'locale-service': localeService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'audi-tracking-service': trackingService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'dbad:audi-i18n-service': i18nService,
      's2:logger': logger,
    } = featureServices;

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    const initialContent = contentService.getContent();
    logger?.info('SVG Scroll Zoom: initialContent', initialContent);
    const content = mapHeadlessContent(initialContent);
    logger?.info('SVG Scroll Zoom: content', content);

    let asyncStateHolder: AsyncStateHolder;
    if (trackingService) {
      trackingService.featureAppName = featureAppId;
    }
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            asyncStateHolder = await createInitialState(content, localeService, i18nService);
            serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
          }
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();
      if (serializedState) {
        asyncStateHolder = deserializeState(serializedState);
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        if (content) {
          asyncStateHolder = () => createInitialState(content, localeService, i18nService);
        }
      }
    }

    return {
      render: () => (
        <InViewContextProvider logger={logger}>
          <ContentContextProvider contentService={contentService}>
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                <ContextProvider featureServices={featureServices}>
                  <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                </ContextProvider>
              </div>
            </FootnoteContextProvider>
          </ContentContextProvider>
        </InViewContextProvider>
      ),
    };
  },
};

export default featureAppDefinition;
