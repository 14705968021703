import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Layout, LayoutItem, Text, TokenizedTheme } from '@audi/audi-ui-react';
import { ConsumptionsAndEmissions } from '@oneaudi/feature-app-utils';
import { AdditionalText } from '../../FeatureAppTypes';
import { isDarkTheme } from '../../utils/use-theme';
import { Context } from '../../context';

const WltpSpan = styled(Text)`
  color: ${({ theme }) =>
    isDarkTheme(theme) ? theme.colors.base.brand.white : theme.colors.base.brand.black};
`;

WltpSpan.displayName = 'WltpSpan';

const LegalArea = styled.div`
  background-color: ${({ theme }) =>
    isDarkTheme(theme) ? theme.colors.base.brand.black : theme.colors.base.brand.white};
`;
interface LegalComponentProps {
  theme: TokenizedTheme;
  wltp: string[];
  additionalText?: AdditionalText;
}

export const LegalComponent: React.FC<LegalComponentProps> = ({ theme, wltp, additionalText }) => {
  if (wltp.length === 0 && !additionalText) return null;

  const { oneGraphService, localeService, logger } = useContext(Context);

  return (
    <ThemeProvider theme={theme}>
      <LegalArea data-testid="wltp">
        <Layout
          spaceInsetStackStart="l"
          spaceInsetStackEnd="xxxl"
          spaceInlineStart="pageMargin"
          spaceInlineEnd="pageMargin"
        >
          <LayoutItem basis={{ xs: '86%', m: '92%' }} shrink="0">
            <WltpSpan variant="copy2">
              {wltp && (
                <ConsumptionsAndEmissions
                  ids={wltp}
                  localeService={localeService}
                  oneGraphService={oneGraphService}
                  logger={logger}
                />
              )}
              {additionalText}
            </WltpSpan>
          </LayoutItem>
        </Layout>
      </LegalArea>
    </ThemeProvider>
  );
};
