import { Asset, ContentFragment, isContentFragment } from '@oneaudi/falcon-tools';
import { presets } from '../presets';
import {
  AssetType,
  Content,
  CustomMask,
  HeadlessContent,
  ImageAsset,
  MaskType,
  PresetMask,
  PresetTypes,
  ThemeType,
  VideoAsset,
} from '../types';

export const getMask = (content: ContentFragment) => {
  if (content.fields.maskType === MaskType.maskPreset) {
    if (content.fields.text === PresetTypes.GT) return presets[PresetTypes.GT];
    if (content.fields.text === PresetTypes.RS) return presets[PresetTypes.RS];
    if (content.fields.text === PresetTypes.RSQ8) return presets[PresetTypes.RSQ8];
    return presets[PresetTypes.SONOS];
  }
  return {
    offsetWidth: content.fields.offsetWidth,
    offsetHeight: content.fields.offsetHeight,
    maskType: content.fields.maskType ?? MaskType.maskPreset,
    text: content.fields.text ?? PresetTypes.GT,
  } as unknown as CustomMask;
};

export const getAsset = (content: ContentFragment): ImageAsset | VideoAsset => {
  if (content.fields.assetType === AssetType.image) {
    return {
      assetType: content.fields.assetType,
      url: {
        assetValue:
          (content.fields.url as unknown as Asset) && (content.fields.url as unknown as Asset).path
            ? (content.fields.url as unknown as Asset).path
            : 'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAA_y2Vvc7lNBCGD7taQBQgQAhxA5-QkJg4_yk-od0KCZBWAtFssXIS_x07TmI7yXdScSlcANdDQ8MNUNHQIPEeierJjMdjj_2O89tflxdbuDx_8-bVd_9-8ZlSfzTPLpen5XK5vAP_szevfvv783_eff-nP_93v_v95UXaQj-_g5DXzz4wE1eCrotQH13eg-vy_HJ575fL5cWv94DLh59uwS088OnrIOIy-2h28fGXIxubfmyaKs_roSiavimGsW6LmrdlU1dlW1aFZCX_BIl-586RV8Mgw-xTUY4i2jQvT47am4zVjfIsr4iVX-W3_EZV_ZUwxDkvVQFsIuPE-7nWGRDYIoiLMR80ELKlJq6m8oBT72xEiOnLTQJO7bBsz0RF3KlVMOJR1E9IHZXgGIuTmu7OWF2xQrxlFs7E2ROcW-Qtshx7ey2o55rZG5Cu-QjszMHCluSVemX9LaPe9T322c8Hi5gQIuMr9Ylnu6RB81zXQFivjIZ5GKqKhoWbVAC92UpguIq7NZqjoJGHImY0ClNITqPU7CoArNcAu8oqEoPwpgFmIRmJUWbmChwNdySMbtIIuD0hctHNExBNc2QkxVajdqmPHfOki60tSc6jXGDFftM5sFQOIWeUqEgJ36qNlNXZTQK7a2tS05yKg1TgLWpQIe1DRiouio2kDl7fctIi8IoBON07jhZnrQ3PfAlMtV-BWPhE2nKrK9LxaA2sbbQRkbc-x-rGx63qyYRYupoMbsxnZHmwbQDiahnZMZaYbt2Z3WBNyxo6srMuUg5MqseEea9OSTZM7ERIiBlqt5FX2LWNm-WI3LS1BdkzFawnx4fKVuSGZV0LcmPvcXRuHP31jlngcpzgLguAZ74BQgF9OsNbdcfkEiNnuYAGnUVvcGBoamAODc7aBZ4vFtj5PSSKbo_ktqnRkiY-ehzWxGMNTUxjlCh6kryLHU1WiCkHtvasaJq5gVpxG1NoyPO92RR57yXKXPikljti1w209LpcSlqG1HUHLcZ3baTFuq5VwNy1CImm6zStapIro3XjfM0o8HHIEzCb9gZsHXolQHWBURhtgwsI0BukGITbcWMBI6qlEFPWY2yT_swp8r4e7lCN7ADNswzYchso9qHdJ4pDWl1FcdSlwJjgq6kpyjOXsJRlBjC9L58AUbgC0CW_48zwukTPGygket1CBXHZGjRlDLo2yJnwVGHZJPPrBuisx4SE3QqK-yzRD_EI7VMLxNbXlOxeYvW0qA7qSYvrs45SkBVkk-LicbdpF-teUDo2Fjml28LEE3DmZ0N7bxnaYp-tQup9jivaYk97d-50cNclBwQBSR1mXxWjI_IGRR8xlkrS2ePp4UDID0BOGZr5NKI8FzotNAGn3QukPjfdTdk3wu8GD-skfHr8Yf7h5dtv5y2Kh8OMST-yLs8etDBKp0dWltkDd4vmj5JDcw8Dn9Cmjz8m_AHevnybv80yBHj8EZLBQ__4eo7m_vX9ffpDTEIE4xX3yolHln36_OXPr_8DKGeqGn8GAAA',
      },
      urlMobile: {
        assetValue:
          (content.fields.url as unknown as Asset) && (content.fields.url as unknown as Asset).path
            ? (content.fields.url as unknown as Asset).path
            : 'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAA_y2Vvc7lNBCGD7taQBQgQAhxA5-QkJg4_yk-od0KCZBWAtFssXIS_x07TmI7yXdScSlcANdDQ8MNUNHQIPEeierJjMdjj_2O89tflxdbuDx_8-bVd_9-8ZlSfzTPLpen5XK5vAP_szevfvv783_eff-nP_93v_v95UXaQj-_g5DXzz4wE1eCrotQH13eg-vy_HJ575fL5cWv94DLh59uwS088OnrIOIy-2h28fGXIxubfmyaKs_roSiavimGsW6LmrdlU1dlW1aFZCX_BIl-586RV8Mgw-xTUY4i2jQvT47am4zVjfIsr4iVX-W3_EZV_ZUwxDkvVQFsIuPE-7nWGRDYIoiLMR80ELKlJq6m8oBT72xEiOnLTQJO7bBsz0RF3KlVMOJR1E9IHZXgGIuTmu7OWF2xQrxlFs7E2ROcW-Qtshx7ey2o55rZG5Cu-QjszMHCluSVemX9LaPe9T322c8Hi5gQIuMr9Ylnu6RB81zXQFivjIZ5GKqKhoWbVAC92UpguIq7NZqjoJGHImY0ClNITqPU7CoArNcAu8oqEoPwpgFmIRmJUWbmChwNdySMbtIIuD0hctHNExBNc2QkxVajdqmPHfOki60tSc6jXGDFftM5sFQOIWeUqEgJ36qNlNXZTQK7a2tS05yKg1TgLWpQIe1DRiouio2kDl7fctIi8IoBON07jhZnrQ3PfAlMtV-BWPhE2nKrK9LxaA2sbbQRkbc-x-rGx63qyYRYupoMbsxnZHmwbQDiahnZMZaYbt2Z3WBNyxo6srMuUg5MqseEea9OSTZM7ERIiBlqt5FX2LWNm-WI3LS1BdkzFawnx4fKVuSGZV0LcmPvcXRuHP31jlngcpzgLguAZ74BQgF9OsNbdcfkEiNnuYAGnUVvcGBoamAODc7aBZ4vFtj5PSSKbo_ktqnRkiY-ehzWxGMNTUxjlCh6kryLHU1WiCkHtvasaJq5gVpxG1NoyPO92RR57yXKXPikljti1w209LpcSlqG1HUHLcZ3baTFuq5VwNy1CImm6zStapIro3XjfM0o8HHIEzCb9gZsHXolQHWBURhtgwsI0BukGITbcWMBI6qlEFPWY2yT_swp8r4e7lCN7ADNswzYchso9qHdJ4pDWl1FcdSlwJjgq6kpyjOXsJRlBjC9L58AUbgC0CW_48zwukTPGygket1CBXHZGjRlDLo2yJnwVGHZJPPrBuisx4SE3QqK-yzRD_EI7VMLxNbXlOxeYvW0qA7qSYvrs45SkBVkk-LicbdpF-teUDo2Fjml28LEE3DmZ0N7bxnaYp-tQup9jivaYk97d-50cNclBwQBSR1mXxWjI_IGRR8xlkrS2ePp4UDID0BOGZr5NKI8FzotNAGn3QukPjfdTdk3wu8GD-skfHr8Yf7h5dtv5y2Kh8OMST-yLs8etDBKp0dWltkDd4vmj5JDcw8Dn9Cmjz8m_AHevnybv80yBHj8EZLBQ__4eo7m_vX9ffpDTEIE4xX3yolHln36_OXPr_8DKGeqGn8GAAA',
      },
      alt: content.fields.alt,
    } as unknown as ImageAsset;
  }
  return {
    assetType: content.fields.assetType,
    sourceDefault: {
      assetValue:
        (content.fields.sourceDefault as unknown as Asset) &&
        (content.fields.sourceDefault as unknown as Asset).path
          ? (content.fields.sourceDefault as unknown as Asset).path
          : 'https://www.audi.de/content/dam/nemo/models/audi-e-tron-gt/e-tron-gt/my-2022/NeMo-Derivate-Startpage/market-introduction/zoom-scroll/08_Zoom_Scroll_Desktop.mp4',
    },
    sourceMobile: {
      assetValue:
        (content.fields.sourceDefault as unknown as Asset) &&
        (content.fields.sourceDefault as unknown as Asset).path
          ? (content.fields.sourceDefault as unknown as Asset).path
          : 'https://www.audi.de/content/dam/nemo/models/audi-e-tron-gt/e-tron-gt/my-2022/NeMo-Derivate-Startpage/market-introduction/zoom-scroll/08_Zoom_Scroll_Desktop.mp4',
    },
    loop: content.fields.loop,
    playMode: content.fields.playMode,
    thresholdValue: content.fields.thresholdValue,
    title: content.fields.title,
    imageFallback: {
      assetValue:
        (content.fields.imageFallback as unknown as Asset) &&
        (content.fields.imageFallback as unknown as Asset).path
          ? (content.fields.imageFallback as unknown as Asset).path
          : 'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAA_y2Vvc7lNBCGD7taQBQgQAhxA5-QkJg4_yk-od0KCZBWAtFssXIS_x07TmI7yXdScSlcANdDQ8MNUNHQIPEeierJjMdjj_2O89tflxdbuDx_8-bVd_9-8ZlSfzTPLpen5XK5vAP_szevfvv783_eff-nP_93v_v95UXaQj-_g5DXzz4wE1eCrotQH13eg-vy_HJ575fL5cWv94DLh59uwS088OnrIOIy-2h28fGXIxubfmyaKs_roSiavimGsW6LmrdlU1dlW1aFZCX_BIl-586RV8Mgw-xTUY4i2jQvT47am4zVjfIsr4iVX-W3_EZV_ZUwxDkvVQFsIuPE-7nWGRDYIoiLMR80ELKlJq6m8oBT72xEiOnLTQJO7bBsz0RF3KlVMOJR1E9IHZXgGIuTmu7OWF2xQrxlFs7E2ROcW-Qtshx7ey2o55rZG5Cu-QjszMHCluSVemX9LaPe9T322c8Hi5gQIuMr9Ylnu6RB81zXQFivjIZ5GKqKhoWbVAC92UpguIq7NZqjoJGHImY0ClNITqPU7CoArNcAu8oqEoPwpgFmIRmJUWbmChwNdySMbtIIuD0hctHNExBNc2QkxVajdqmPHfOki60tSc6jXGDFftM5sFQOIWeUqEgJ36qNlNXZTQK7a2tS05yKg1TgLWpQIe1DRiouio2kDl7fctIi8IoBON07jhZnrQ3PfAlMtV-BWPhE2nKrK9LxaA2sbbQRkbc-x-rGx63qyYRYupoMbsxnZHmwbQDiahnZMZaYbt2Z3WBNyxo6srMuUg5MqseEea9OSTZM7ERIiBlqt5FX2LWNm-WI3LS1BdkzFawnx4fKVuSGZV0LcmPvcXRuHP31jlngcpzgLguAZ74BQgF9OsNbdcfkEiNnuYAGnUVvcGBoamAODc7aBZ4vFtj5PSSKbo_ktqnRkiY-ehzWxGMNTUxjlCh6kryLHU1WiCkHtvasaJq5gVpxG1NoyPO92RR57yXKXPikljti1w209LpcSlqG1HUHLcZ3baTFuq5VwNy1CImm6zStapIro3XjfM0o8HHIEzCb9gZsHXolQHWBURhtgwsI0BukGITbcWMBI6qlEFPWY2yT_swp8r4e7lCN7ADNswzYchso9qHdJ4pDWl1FcdSlwJjgq6kpyjOXsJRlBjC9L58AUbgC0CW_48zwukTPGygket1CBXHZGjRlDLo2yJnwVGHZJPPrBuisx4SE3QqK-yzRD_EI7VMLxNbXlOxeYvW0qA7qSYvrs45SkBVkk-LicbdpF-teUDo2Fjml28LEE3DmZ0N7bxnaYp-tQup9jivaYk97d-50cNclBwQBSR1mXxWjI_IGRR8xlkrS2ePp4UDID0BOGZr5NKI8FzotNAGn3QukPjfdTdk3wu8GD-skfHr8Yf7h5dtv5y2Kh8OMST-yLs8etDBKp0dWltkDd4vmj5JDcw8Dn9Cmjz8m_AHevnybv80yBHj8EZLBQ__4eo7m_vX9ffpDTEIE4xX3yolHln36_OXPr_8DKGeqGn8GAAA',
    },
    imageFallbackMobile: {
      assetValue:
        (content.fields.imageFallback as unknown as Asset) &&
        (content.fields.imageFallback as unknown as Asset).path
          ? (content.fields.imageFallback as unknown as Asset).path
          : 'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAA_y2Vvc7lNBCGD7taQBQgQAhxA5-QkJg4_yk-od0KCZBWAtFssXIS_x07TmI7yXdScSlcANdDQ8MNUNHQIPEeierJjMdjj_2O89tflxdbuDx_8-bVd_9-8ZlSfzTPLpen5XK5vAP_szevfvv783_eff-nP_93v_v95UXaQj-_g5DXzz4wE1eCrotQH13eg-vy_HJ575fL5cWv94DLh59uwS088OnrIOIy-2h28fGXIxubfmyaKs_roSiavimGsW6LmrdlU1dlW1aFZCX_BIl-586RV8Mgw-xTUY4i2jQvT47am4zVjfIsr4iVX-W3_EZV_ZUwxDkvVQFsIuPE-7nWGRDYIoiLMR80ELKlJq6m8oBT72xEiOnLTQJO7bBsz0RF3KlVMOJR1E9IHZXgGIuTmu7OWF2xQrxlFs7E2ROcW-Qtshx7ey2o55rZG5Cu-QjszMHCluSVemX9LaPe9T322c8Hi5gQIuMr9Ylnu6RB81zXQFivjIZ5GKqKhoWbVAC92UpguIq7NZqjoJGHImY0ClNITqPU7CoArNcAu8oqEoPwpgFmIRmJUWbmChwNdySMbtIIuD0hctHNExBNc2QkxVajdqmPHfOki60tSc6jXGDFftM5sFQOIWeUqEgJ36qNlNXZTQK7a2tS05yKg1TgLWpQIe1DRiouio2kDl7fctIi8IoBON07jhZnrQ3PfAlMtV-BWPhE2nKrK9LxaA2sbbQRkbc-x-rGx63qyYRYupoMbsxnZHmwbQDiahnZMZaYbt2Z3WBNyxo6srMuUg5MqseEea9OSTZM7ERIiBlqt5FX2LWNm-WI3LS1BdkzFawnx4fKVuSGZV0LcmPvcXRuHP31jlngcpzgLguAZ74BQgF9OsNbdcfkEiNnuYAGnUVvcGBoamAODc7aBZ4vFtj5PSSKbo_ktqnRkiY-ehzWxGMNTUxjlCh6kryLHU1WiCkHtvasaJq5gVpxG1NoyPO92RR57yXKXPikljti1w209LpcSlqG1HUHLcZ3baTFuq5VwNy1CImm6zStapIro3XjfM0o8HHIEzCb9gZsHXolQHWBURhtgwsI0BukGITbcWMBI6qlEFPWY2yT_swp8r4e7lCN7ADNswzYchso9qHdJ4pDWl1FcdSlwJjgq6kpyjOXsJRlBjC9L58AUbgC0CW_48zwukTPGygket1CBXHZGjRlDLo2yJnwVGHZJPPrBuisx4SE3QqK-yzRD_EI7VMLxNbXlOxeYvW0qA7qSYvrs45SkBVkk-LicbdpF-teUDo2Fjml28LEE3DmZ0N7bxnaYp-tQup9jivaYk97d-50cNclBwQBSR1mXxWjI_IGRR8xlkrS2ePp4UDID0BOGZr5NKI8FzotNAGn3QukPjfdTdk3wu8GD-skfHr8Yf7h5dtv5y2Kh8OMST-yLs8etDBKp0dWltkDd4vmj5JDcw8Dn9Cmjz8m_AHevnybv80yBHj8EZLBQ__4eo7m_vX9ffpDTEIE4xX3yolHln36_OXPr_8DKGeqGn8GAAA',
    },
  } as unknown as VideoAsset;
};

function isFalconContent(content: unknown): content is HeadlessContent {
  return Boolean(
    isContentFragment(content) &&
      'theme' in content.fields &&
      content.fields.theme &&
      'content_mask' in content.fields &&
      content.fields.content_mask &&
      'content_asset' in content.fields &&
      content.fields.content_asset,
  );
}

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if (content && '__type' in content && content.__type === 'aem-headless') {
    if (!isFalconContent(content)) {
      return {
        theme: ThemeType.light,
        content: {
          mask: presets[PresetTypes.GT],
          showGuide: false,
          asset: {
            assetType: AssetType.image,
            url: {
              assetValue:
                'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAA_y2Vvc7lNBCGD7taQBQgQAhxA5-QkJg4_yk-od0KCZBWAtFssXIS_x07TmI7yXdScSlcANdDQ8MNUNHQIPEeierJjMdjj_2O89tflxdbuDx_8-bVd_9-8ZlSfzTPLpen5XK5vAP_szevfvv783_eff-nP_93v_v95UXaQj-_g5DXzz4wE1eCrotQH13eg-vy_HJ575fL5cWv94DLh59uwS088OnrIOIy-2h28fGXIxubfmyaKs_roSiavimGsW6LmrdlU1dlW1aFZCX_BIl-586RV8Mgw-xTUY4i2jQvT47am4zVjfIsr4iVX-W3_EZV_ZUwxDkvVQFsIuPE-7nWGRDYIoiLMR80ELKlJq6m8oBT72xEiOnLTQJO7bBsz0RF3KlVMOJR1E9IHZXgGIuTmu7OWF2xQrxlFs7E2ROcW-Qtshx7ey2o55rZG5Cu-QjszMHCluSVemX9LaPe9T322c8Hi5gQIuMr9Ylnu6RB81zXQFivjIZ5GKqKhoWbVAC92UpguIq7NZqjoJGHImY0ClNITqPU7CoArNcAu8oqEoPwpgFmIRmJUWbmChwNdySMbtIIuD0hctHNExBNc2QkxVajdqmPHfOki60tSc6jXGDFftM5sFQOIWeUqEgJ36qNlNXZTQK7a2tS05yKg1TgLWpQIe1DRiouio2kDl7fctIi8IoBON07jhZnrQ3PfAlMtV-BWPhE2nKrK9LxaA2sbbQRkbc-x-rGx63qyYRYupoMbsxnZHmwbQDiahnZMZaYbt2Z3WBNyxo6srMuUg5MqseEea9OSTZM7ERIiBlqt5FX2LWNm-WI3LS1BdkzFawnx4fKVuSGZV0LcmPvcXRuHP31jlngcpzgLguAZ74BQgF9OsNbdcfkEiNnuYAGnUVvcGBoamAODc7aBZ4vFtj5PSSKbo_ktqnRkiY-ehzWxGMNTUxjlCh6kryLHU1WiCkHtvasaJq5gVpxG1NoyPO92RR57yXKXPikljti1w209LpcSlqG1HUHLcZ3baTFuq5VwNy1CImm6zStapIro3XjfM0o8HHIEzCb9gZsHXolQHWBURhtgwsI0BukGITbcWMBI6qlEFPWY2yT_swp8r4e7lCN7ADNswzYchso9qHdJ4pDWl1FcdSlwJjgq6kpyjOXsJRlBjC9L58AUbgC0CW_48zwukTPGygket1CBXHZGjRlDLo2yJnwVGHZJPPrBuisx4SE3QqK-yzRD_EI7VMLxNbXlOxeYvW0qA7qSYvrs45SkBVkk-LicbdpF-teUDo2Fjml28LEE3DmZ0N7bxnaYp-tQup9jivaYk97d-50cNclBwQBSR1mXxWjI_IGRR8xlkrS2ePp4UDID0BOGZr5NKI8FzotNAGn3QukPjfdTdk3wu8GD-skfHr8Yf7h5dtv5y2Kh8OMST-yLs8etDBKp0dWltkDd4vmj5JDcw8Dn9Cmjz8m_AHevnybv80yBHj8EZLBQ__4eo7m_vX9ffpDTEIE4xX3yolHln36_OXPr_8DKGeqGn8GAAA',
            },
            urlMobile: {
              assetValue:
                'https://mediaservice.audi.com/media/fast/H4sIAAAAAAAA_y2Vvc7lNBCGD7taQBQgQAhxA5-QkJg4_yk-od0KCZBWAtFssXIS_x07TmI7yXdScSlcANdDQ8MNUNHQIPEeierJjMdjj_2O89tflxdbuDx_8-bVd_9-8ZlSfzTPLpen5XK5vAP_szevfvv783_eff-nP_93v_v95UXaQj-_g5DXzz4wE1eCrotQH13eg-vy_HJ575fL5cWv94DLh59uwS088OnrIOIy-2h28fGXIxubfmyaKs_roSiavimGsW6LmrdlU1dlW1aFZCX_BIl-586RV8Mgw-xTUY4i2jQvT47am4zVjfIsr4iVX-W3_EZV_ZUwxDkvVQFsIuPE-7nWGRDYIoiLMR80ELKlJq6m8oBT72xEiOnLTQJO7bBsz0RF3KlVMOJR1E9IHZXgGIuTmu7OWF2xQrxlFs7E2ROcW-Qtshx7ey2o55rZG5Cu-QjszMHCluSVemX9LaPe9T322c8Hi5gQIuMr9Ylnu6RB81zXQFivjIZ5GKqKhoWbVAC92UpguIq7NZqjoJGHImY0ClNITqPU7CoArNcAu8oqEoPwpgFmIRmJUWbmChwNdySMbtIIuD0hctHNExBNc2QkxVajdqmPHfOki60tSc6jXGDFftM5sFQOIWeUqEgJ36qNlNXZTQK7a2tS05yKg1TgLWpQIe1DRiouio2kDl7fctIi8IoBON07jhZnrQ3PfAlMtV-BWPhE2nKrK9LxaA2sbbQRkbc-x-rGx63qyYRYupoMbsxnZHmwbQDiahnZMZaYbt2Z3WBNyxo6srMuUg5MqseEea9OSTZM7ERIiBlqt5FX2LWNm-WI3LS1BdkzFawnx4fKVuSGZV0LcmPvcXRuHP31jlngcpzgLguAZ74BQgF9OsNbdcfkEiNnuYAGnUVvcGBoamAODc7aBZ4vFtj5PSSKbo_ktqnRkiY-ehzWxGMNTUxjlCh6kryLHU1WiCkHtvasaJq5gVpxG1NoyPO92RR57yXKXPikljti1w209LpcSlqG1HUHLcZ3baTFuq5VwNy1CImm6zStapIro3XjfM0o8HHIEzCb9gZsHXolQHWBURhtgwsI0BukGITbcWMBI6qlEFPWY2yT_swp8r4e7lCN7ADNswzYchso9qHdJ4pDWl1FcdSlwJjgq6kpyjOXsJRlBjC9L58AUbgC0CW_48zwukTPGygket1CBXHZGjRlDLo2yJnwVGHZJPPrBuisx4SE3QqK-yzRD_EI7VMLxNbXlOxeYvW0qA7qSYvrs45SkBVkk-LicbdpF-teUDo2Fjml28LEE3DmZ0N7bxnaYp-tQup9jivaYk97d-50cNclBwQBSR1mXxWjI_IGRR8xlkrS2ePp4UDID0BOGZr5NKI8FzotNAGn3QukPjfdTdk3wu8GD-skfHr8Yf7h5dtv5y2Kh8OMST-yLs8etDBKp0dWltkDd4vmj5JDcw8Dn9Cmjz8m_AHevnybv80yBHj8EZLBQ__4eo7m_vX9ffpDTEIE4xX3yolHln36_OXPr_8DKGeqGn8GAAA',
            },

            alt: 'Cool alt text',
          },
        },
        themeWltp: 'light',
        wltp: [],
      };
    }
    const normalizedContent: Content = {
      theme: ThemeType.light === content.fields.theme ? ThemeType.light : ThemeType.dark,
      content: {
        mask:
          content.fields.content_mask.fields.maskType === MaskType.maskCustom
            ? getMask(content.fields.content_mask)
            : (getMask(content.fields.content_mask) as PresetMask),
        showGuide: content.fields.content_showGuide,
        asset: getAsset(content.fields.content_asset),
      },
      themeWltp: content.fields.themeWltp,
      wltp: content.fields.wltp,
      additionalText: content.fields.additionalText,
    };
    return normalizedContent;
  }
  return content as Content;
};
